import React from 'react';
import Footer from 'components/footer/Footer';
import Navbar from 'components/navbar/Navbar';

const ContactUs = () => {
    return (
        <>
            <Navbar />
            <div className="container my-6 px-6 mx-auto">

                <section className="mb-32 text-gray-800">
                    <div className="block rounded-lg shadow-lg bg-white">
                        <div className="flex flex-wrap items-center">
                            <div className="grow-0 shrink-0 basis-auto block w-full lg:flex lg:w-6/12 xl:w-4/12">
                                <div className="map-container-2 w-full">
                                    <iframe title="COJIM Map" src="https://maps.google.com/maps?q=CITY+OF+JESUS+INTERNATIONAL+MINISTRY,+400104,+Enugu&t=&z=13&ie=UTF8&iwloc=&output=embed" className="left-0 top-0 h-full w-full rounded-t-lg lg:rounded-tr-none lg:rounded-bl-lg" frameborder="0" allowfullscreen></iframe>
                                </div>
                            </div>

                            <div className="grow-0 shrink-0 basis-auto w-full lg:w-6/12 xl:w-8/12">
                                <div className="grow-0 shrink-0 basis-auto m-4 mb-6 md:mb-0 w-full px-3 lg:px-6">
                                    <h2 className="text-3xl font-bold mb-6 text-primary">Contact us</h2>
                                    <p className="text-gray-500 mb-6">
                                        Welcome to Lovers of God International Foundation-LOGIF (Non Governmental Org) contact page.
                                        We're here to help answer any questions you may have about our organization and the work we do.
                                        Please feel free to reach out to us by using the contact information provided.
                                        We'll get back to you as soon as we can. Thank you for your interest in our cause!
                                    </p>
                                    <p className="text-gray-500 mb-2">
                                        IN/20 Emene Industrial Layout,
                                        Enugu East Local Government Area, Enugu State, Nigeria, West Africa
                                    </p>
                                    <p className="text-gray-500 mb-2">info@logif.org</p>
                                    <div className="grow-0 shrink-0 basis-auto mt-4 w-full">
                                        <div className="flex flex-wrap">
                                            
                                            <div className="mb-12 grow-0 shrink-0 basis-auto w-full lg:w-6/12 px-3 lg:px-6">
                                                <div className="flex align-start">
                                                    <div className="shrink-0">
                                                        <div className="p-4 bg-blue-600 rounded-md shadow-md w-14 h-14 flex items-center justify-center">
                                                            <svg aria-hidden="true" focusable="false" data-prefix="fas" data-icon="headset" className="w-5 text-white"
                                                                role="img" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512">
                                                                <path fill="currentColor"
                                                                    d="M192 208c0-17.67-14.33-32-32-32h-16c-35.35 0-64 28.65-64 64v48c0 35.35 28.65 64 64 64h16c17.67 0 32-14.33 32-32V208zm176 144c35.35 0 64-28.65 64-64v-48c0-35.35-28.65-64-64-64h-16c-17.67 0-32 14.33-32 32v112c0 17.67 14.33 32 32 32h16zM256 0C113.18 0 4.58 118.83 0 256v16c0 8.84 7.16 16 16 16h16c8.84 0 16-7.16 16-16v-16c0-114.69 93.31-208 208-208s208 93.31 208 208h-.12c.08 2.43.12 165.72.12 165.72 0 23.35-18.93 42.28-42.28 42.28H320c0-26.51-21.49-48-48-48h-32c-26.51 0-48 21.49-48 48s21.49 48 48 48h181.72c49.86 0 90.28-40.42 90.28-90.28V256C507.42 118.83 398.82 0 256 0z">
                                                                </path>
                                                            </svg>
                                                        </div>
                                                    </div>
                                                    <div className="grow ml-6">
                                                        <p className="font-bold mb-1">Visit Line</p>
                                                        <p className="text-gray-500">+2347037278975</p>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="mb-12 grow-0 shrink-0 basis-auto w-full lg:w-6/12 px-3 lg:px-6">
                                                <div className="flex align-start">
                                                    <div className="shrink-0">
                                                        <div className="p-4 bg-blue-600 rounded-md shadow-md w-14 h-14 flex items-center justify-center">
                                                            <svg aria-hidden="true" focusable="false" data-prefix="fas" data-icon="headset" className="w-5 text-white"
                                                                role="img" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512">
                                                                <path fill="currentColor"
                                                                    d="M192 208c0-17.67-14.33-32-32-32h-16c-35.35 0-64 28.65-64 64v48c0 35.35 28.65 64 64 64h16c17.67 0 32-14.33 32-32V208zm176 144c35.35 0 64-28.65 64-64v-48c0-35.35-28.65-64-64-64h-16c-17.67 0-32 14.33-32 32v112c0 17.67 14.33 32 32 32h16zM256 0C113.18 0 4.58 118.83 0 256v16c0 8.84 7.16 16 16 16h16c8.84 0 16-7.16 16-16v-16c0-114.69 93.31-208 208-208s208 93.31 208 208h-.12c.08 2.43.12 165.72.12 165.72 0 23.35-18.93 42.28-42.28 42.28H320c0-26.51-21.49-48-48-48h-32c-26.51 0-48 21.49-48 48s21.49 48 48 48h181.72c49.86 0 90.28-40.42 90.28-90.28V256C507.42 118.83 398.82 0 256 0z">
                                                                </path>
                                                            </svg>
                                                        </div>
                                                    </div>
                                                    <div className="grow ml-6">
                                                        <p className="font-bold mb-1">Testimony Line</p>
                                                        <p className="text-gray-500">+2348159602829</p>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </section>

            </div>
            <Footer />
        </>
    )
}

export default ContactUs;