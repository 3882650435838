import React from 'react';
import Stats from 'components/Stats';

const Overview = () => {
    return (
        <>
            <Stats />
        </>
    )
}

export default Overview